import '@babel/polyfill';
import '../sass/style.scss';
import init from './init.js';

/* -----------------------------------------------------------------
* Ready
----------------------------------------------------------------- */
document.addEventListener('DOMContentLoaded', () => {
  init.init();
});
