import $ from 'jquery';
// import { debounce, } from 'throttle-debounce';
import inView from 'in-view';

function init() {
  onScrollHeaderFix();
  pageScroll();
  inview();
}

function onScrollHeaderFix() {
  const $header = $('.l-header');

  if (!$header[0]) {
    return;
  }

  $(window).on('scroll', () => {
    if ($(window).width() < 840) {
      $('body').removeClass('-onScroll');
      return;
    }

    const $headerH = $header.innerHeight();
    const scrollTop = $(window).scrollTop();

    if (scrollTop > $headerH) {
      $('body').addClass('-onScroll');
    } else {
      $('body').removeClass('-onScroll');
    }
  });
}

function pageScroll() {
  $('.c-smoothScroll').click((e) => {
    let $headerH = $('.l-header').innerHeight();
    const scrollTop = $(window).scrollTop();
    const speed = 1000;
    const href = $(e.currentTarget).attr('href');
    const target = $((href === '#' || href === '') ? 'html' : href);
    const position = target.offset().top;

    if ($(window).width() > 840 && scrollTop < $headerH) {
      $headerH = 65;
    }

    e.preventDefault();
    $('body, html').animate({ scrollTop: position - $headerH, }, speed);
  });
}

function inview() {
  const $elm = $('.c-inview');

  if (!$elm[0]) {
    return;
  }

  inView.offset(150);
  inView('.c-inview').on('enter', el => {
    $(el).addClass('-visible');
  });
}

export default {
  init,
};
