import $ from 'jquery';
import 'slick-carousel';
// import { debounce, } from 'throttle-debounce';

function init() {
  topSlide();
  innerSlide();
  detailSlide();
}

function topSlide() {
  const $slide = $('.p-topSlider__slide');
  const autoSpeed = 5000;
  const slideSpeed = 3000;

  if (!$slide[0]) {
    return;
  }

  $slide.slick({
    autoplay: true,
    autoplaySpeed: autoSpeed,
    infinite: true,
    centerMode: true,
    centerPadding: '20%',
    speed: slideSpeed,
    arrows: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          centerPadding: '10%',
          touchMove: true,
        },
      },
      {
        breakpoint: 640,
        settings: {
          centerMode: false,
        },
      },
    ],
  });
}

function innerSlide() {
  const $slide = $('.c-slide');

  if (!$slide[0]) {
    return;
  }

  $slide.each((i, slide) => {
    slideItem(slide);
  });
}

function slideItem(slide) {
  const $slide = $(slide);
  const autoSpeed = 2500;
  const slideSpeed = 2000;

  $slide.slick({
    autoplay: true,
    autoplaySpeed: autoSpeed,
    infinite: true,
    speed: slideSpeed,
    centerMode: true,
    // centerPadding: '100px',
    // slidesToShow: 3,
    variableWidth: true,
    arrows: false,
    swipe: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          touchMove: true,
        },
      },
    ],
  });
}

function detailSlide() {
  const $slide = $('.p-worksDetail__slide');

  if (!$slide[0]) {
    return;
  }

  const slideSpeed = 1000;

  $slide.on('init', () => {
    const $nav = $('.c-slideNav').find('li');
    const $slideImg = $('.p-worksDetail__slideImg').find('img');

    $slideImg.each((i, img) => {
      const src = $(img).attr('src');

      $nav.each((j, li) => {
        if (i === j) {
          const $button = $(li).find('button');

          $button.css('backgroundImage', `url('${src}')`);
        }
      });
    });
  });

  $slide.slick({
    autoplay: false,
    speed: slideSpeed,
    adaptiveHeight: true,
    arrows: true,
    prevArrow: '<div class="c-arrow -prev"></div>',
    nextArrow: '<div class="c-arrow -next"></div>',
    appendArrows: $('.p-worksDetail__slideArrows'),
    dots: true,
    dotsClass: 'c-slideNav',
    appendDots: $('.p-worksDetail__slideDots'),
    fade: true,
    // asNavFor: '.c-slideNav',
    // swipe: true,
    // responsive: [
    //   {
    //     breakpoint: 768,
    //     settings: {
    //       touchMove: true,
    //     },
    //   },
    // ],
  });

  // $slideNav.slick({

  //   asNavFor: $slide,
  // });
}

export default {
  init,
};
