import $ from 'jquery';
import inView from 'in-view';

function init() {
  onClickedSort();
  getWorksPosts();
}

let data = null;

function onClickedSort() {
  const $elm = $('#p-works__items');
  const $lists = $('.p-works__sort').find('li');

  if (!$lists[0]) {
    return;
  }

  $lists.on('click', (e => {
    const $this = $(e.currentTarget);
    const type = $this.attr('data-type');

    if (!$this.hasClass('-active')) {
      $lists.removeClass('-active');
      $this.addClass('-active');
    }

    $elm.empty();
    setItems($elm, type, data);
  }));
}

function getPosts(url, $elm, type) {
  if (!data) {
    $.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
    }).done((json) => {
      console.log(json);
      setItems($elm, type, json);
      data = json;
    }).fail((err) => {
      console.log(err);
    });
  }
}

function getWorksPosts(type) {
  const $elm = $('#p-works__items');
  const $type = type || null;
  const host = location.hostname;
  const url = `https://${host}/wp-json/wp/v2/posts?_embed&per_page=50`;

  if (!$elm[0]) {
    return;
  }

  getPosts(url, $elm, $type);
}

function setItems($elm, type, json) {
  const items = $elm.attr('data-items');
  let currentId = '';
  let itemLength = '';
  let html = '';
  let count = 0;

  console.log(items);

  if ($elm.attr('data-current')) {
    currentId = $elm.attr('data-current');
  }

  console.log(json);
  console.log(items);

  if (items === 'all' || parseInt(items, 10) > json.length) {
    itemLength = json.length;
  } else {
    itemLength = parseInt(items, 10) + 1;
  }

  console.log(items);
  console.log(itemLength);
  console.log(currentId);

  for (let i = 0; i < itemLength; i++) {
    const $json = json[i];

    if (!$json) {
      return;
    }

    const title = $json.title.rendered;
    const link = $json.link;
    const thumb = $json._embedded['wp:featuredmedia'][0].media_details.sizes.full.source_url;
    const tags = $json._embedded['wp:term'][1];
    const category = $json._embedded['wp:term'][0][0].name || '';
    const id = String($json.id);
    let list = '';

    tags.forEach((tag) => {
      const name = tag.name;

      list += `<li>${name}</li>`;
    });

    if (currentId !== id) {
      if (type === null || type === category || type === 'all') {
        html += `
          <div class="p-worksItem c-inview">
            <a href="${link}">
              <div class="p-worksItem__img">
                <img src="${thumb}" alt="${title}">
              </div>
              <div class="p-worksItem__texts">
                <div class="p-worksItem__title">${title}</div>
                <div class="p-worksItem__content">
                  <ul>${list}</ul>
                </div>
              </div>
            </a>
          </div>
        `;
      }
      count++;
    }

    if (currentId !== '' && count === itemLength - 1) {
      break;
    }
  }

  $elm.append(html);
  inview();
}

function inview() {
  const $elm = $('.c-inview');

  if (!$elm[0]) {
    return;
  }

  inView.offset(150);
  inView('.c-inview').on('enter', el => {
    $(el).addClass('-visible');
  });
}

export default {
  init,
};
