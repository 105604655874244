import common from './modules/common.js';
import slide from './modules/slide.js';
import works from './views/works.js';

function init() {
  // modules
  common.init();
  slide.init();
  // views
  works.init();
}

export default {
  init,
};
